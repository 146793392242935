import dayjs from 'dayjs';
import * as Yup from 'yup';

export const makeOfferFormValidationSchema = (
  price: number,
  firstMonth: number,
  lastMonth: number,
  deposit: number,
  axxelistFee: number,
  otherFeeSum: number,
  fixedCommission: number,
) =>
  Yup.object({
    date: Yup.date().required('Date is required').min(dayjs().add(-1, 'day'), 'Date must be today or a future date'),
    proposedRent: Yup.number()
      .required('Proposed rent is required')
      .min(price, `Proposed rent is lower than the list price`)
      .test('max-proposed-rent', function (value) {
        const axxelistFees = axxelistFee > 0 ? axxelistFee * price : fixedCommission;
        const maxProposedRent = 999999 - (firstMonth + lastMonth + deposit + axxelistFees + otherFeeSum);
        const result = value <= maxProposedRent;
        if (!result) return this.createError({ message: `Max proposed rent is ${maxProposedRent}` });
        else return true;
      }),
    occupants: Yup.number()
      .required('Occupants is required')
      .min(1, 'Min number of occupants is 1')
      .max(15, 'Max number of occupants is 15'),
    note: Yup.string().required('Description is required'),
    // videos: Yup.array().min(1, 'Video is required').required('Video is required'),
  });
