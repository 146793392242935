import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import { UpdateCurrentUserDto } from 'common/types/services/api/user/update-current-user-dto.type';
import { UpdateCurrentUserPhoneDto } from 'common/types/services/api/user/update-current-user-phone-dto.type';
import { UserDetailsDto } from 'common/types/services/api/user/user-details-dto.type';
import { updateCurrentUser, updateCurrentUserPhone, updateUserProfile } from 'services/api/auth-api.service';

export const useUpdateCurrentUser = (
  options?: Omit<UseMutationOptions<boolean, AxiosError<BackendError>, UpdateCurrentUserDto, unknown>, 'mutationFn'>,
) => {
  return useMutation({
    mutationFn: updateCurrentUser,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};

export const useUpdateUserProfile = (
  options?: Omit<UseMutationOptions<boolean, AxiosError<BackendError>, Partial<Omit<UserDetailsDto, 'id'>>, unknown>, 'mutationFn'>,
) => {
  return useMutation({
    mutationFn: updateUserProfile,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};

export const useUpdateCurrentUserPhone = (
  options?: Omit<UseMutationOptions<boolean, AxiosError<BackendError>, UpdateCurrentUserPhoneDto, unknown>, 'mutationFn'>,
) => {
  return useMutation({
    mutationFn: updateCurrentUserPhone,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
