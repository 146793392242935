import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, GoBackLink, Loader, NotificationTypeIcon } from 'legacy-components/componets';
import { ApplicationPersonalInfo, ApplicationPropertyCard, ApplicationPropertyDetails } from './components/components';
import { getDate } from 'helpers/date';
import { OfferStatusDto, offerStatusMap } from 'common/enums/services/api/offers/offer-status-dto.enum';
import clsx from 'clsx';
import { EstateTypeDto } from 'common/enums/enums';
import { useAcceptOffer, useRejectOffer, useOfferDetails } from 'hooks/query';
import { notification } from 'services/services';
import { RejectOfferModalByLandlordModal } from 'components/organisms/RejectOfferModalByLandlord/RejectOfferModalByLandlord';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { ApplicationPropertyRenterDocumentsDetails } from 'components/templates/Offers/ApplicationPropertyRenterDocumentsDetails';
import { useUnverifiedProfileActionGuard } from 'hooks/use-unverified-profile-guard';

export const WARNING_MSG = 'This offer was rejected';
export const REASON = 'Rejection reason:';
export const REASON_NOT_EXISTS = 'You did not provide a reason for rejection.';

const ApplicationSummary = () => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const navigate = useNavigate();
  const { data: offerDetails, isLoading: isOfferDetailsLoading } = useOfferDetails(applicationId);
  const { mutateAsync: acceptOffer } = useAcceptOffer({
    onError: (e) => {
      notification.error(e?.response?.data.detail || 'Offer cannot be accepted, try again later.');
    },
  });
  const { mutateAsync: rejectOffer, isPending: isRejectionPending } = useRejectOffer({
    onError: (e) => {
      notification.error(e?.response?.data.detail || 'Offer cannot be rejected, try again later.');
    },
  });

  const { withActionGuard } = useUnverifiedProfileActionGuard({
    modalProps: {
      actionText: 'accept or reject an offer',
    },
  });

  const handleAccept = React.useCallback(async () => {
    if (!offerDetails) return;
    await acceptOffer(offerDetails.id);
  }, [offerDetails, withActionGuard]);

  const handleReject = React.useCallback(async () => {
    if (!offerDetails) return;
    setOpenRejectModal(true);
  }, [offerDetails, withActionGuard]);

  if (isOfferDetailsLoading) {
    return <Loader />;
  }

  return (
    <div className='application-summary w-full'>
      <RejectOfferModalByLandlordModal
        isOpen={openRejectModal}
        isSubmitting={isRejectionPending}
        onClose={() => setOpenRejectModal(false)}
        onSubmit={rejectOffer}
        offerId={applicationId}
      />
      <div className='flex gap-6 flex-wrap justify-between items-center px-6 lg:px-3 mb-5'>
        <GoBackLink title={'Back'} onClick={() => navigate(-1)} />
        <div className='button-group flex-wrap flex gap-6 items-center'>
          {offerDetails && (
            <>
              <div className={'flex items-center gap-3'}>
                <span className={'font-bold'}>Status: </span>
                <span
                  className={clsx(
                    'inline-block text-white rounded px-2 py-1',
                    offerDetails.status === OfferStatusDto.Accept && 'bg-green-400',
                    offerDetails.status === OfferStatusDto.Reject && 'bg-red-400',
                    offerDetails.status === OfferStatusDto.New && 'bg-orange-400',
                    offerDetails.status === OfferStatusDto.PaymentInProcess && 'bg-orange-400',
                    offerDetails.status === OfferStatusDto.Paid && 'bg-primary',
                    offerDetails.status === OfferStatusDto.Cancel && 'bg-trueGray bg-opacity-60',
                    offerDetails.status === OfferStatusDto.PendingPayment && 'bg-yellow-400'
                  )}
                >
                  {offerStatusMap[offerDetails.status]}
                </span>
              </div>
              {offerDetails.status === OfferStatusDto.New && (
                <div className='flex gap-6 flex-wrap items-center'>
                  <Button
                    onClick={() => withActionGuard(() => handleAccept())}
                    label={'Accept'}
                    theme={'primary'}
                    icon={{ name: 'circle-checked', width: 20, height: 20 }}
                  />
                  <Button
                    onClick={() => withActionGuard(() => handleReject())}
                    label={'Reject'}
                    theme={'red'}
                    icon={{ name: 'times-circle', width: 20, height: 20 }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className='wrapper flex flex-col gap-6 rounded-lg px-6 py-8 p-6 border-[1.5px] border-primary'>
        <h2 className='text-2xl font-bold'>Application Summary</h2>
        {offerDetails?.status === OfferStatusDto.Reject && (
          <>
            <div className='flex p-2 items-center justify-start gap-3 rounded-xl bg-secondary w-max'>
              <NotificationTypeIcon type='error' />
              <div className='text-base text-error'>{WARNING_MSG}</div>
            </div>
            {
              <div className='flex flex-col p-2 items-start'>
                <div className='text-base font-bold mb-2'>{REASON}</div>
                <div className='text-base border border-primary w-full pl-4 p-2 rounded-lg text-wrapper text-ellipsis overflow-hidden ...'>
                  {isNotEmptyString(offerDetails?.rejectReason) ? offerDetails?.rejectReason : REASON_NOT_EXISTS}
                </div>
              </div>
            }
          </>
        )}
        <div className='flex w-full justify-center items-center'>
          <ApplicationPropertyCard
            id={offerDetails?.estateId || ''}
            title={offerDetails?.city || ''}
            address={offerDetails?.location || ''}
            image={offerDetails?.thumbnailUrl || ''}
            isMultiUnit={(offerDetails?.type as EstateTypeDto) === EstateTypeDto.MultiUnit}
            unitNumber={offerDetails?.unitNumber || ''}
            info={{
              beds: offerDetails?.beds || 0,
              bathrooms: offerDetails?.bathrooms || 0,
              area: offerDetails?.propertyArea || 0,
              hideUnits: true,
            }}
            appliedAt={offerDetails?.appliedAt || ''}
          />
        </div>

        <ApplicationPropertyDetails
          detailsMessage={offerDetails?.description || ''}
          incomeToRent={
            Boolean(offerDetails?.price)
              ? Number(((offerDetails?.proposedRent || 0) / (offerDetails?.price || 0)).toFixed(1))
              : Number((offerDetails?.proposedRent || 0).toFixed(1))
          }
          moveInDate={getDate(offerDetails?.moveInDate)}
          occupants={offerDetails?.occupants || 0}
          totalIncome={offerDetails?.price || 0}
        />
        <ApplicationPropertyRenterDocumentsDetails />
        <ApplicationPersonalInfo
          firstName={offerDetails?.renterName || ''}
          lastName={''}
          isVerified
          state={''}
          city={''}
          years={offerDetails?.renterAge ?? 0}
          dateOfBirth={getDate(offerDetails?.renterDateOfBirth)}
          // phoneNumber={data?.renterPhoneNumber || ''}
          // email={data?.renterEmail || ''}
          img={offerDetails?.renterThumbnailUrl ?? null}
          ssn={offerDetails?.ssn || ''}
        />
      </div>
    </div>
  );
};

export { ApplicationSummary };
