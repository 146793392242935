import { EstateFiltersRequestDto, EstateDto, EstateUnitDto } from 'common/types/types';
import { IEstateCard, IEstateCardStatus } from 'legacy-components/card/card';
import { EstateSearchBarValues } from 'legacy-pages/estates/estate-search-bar/estate-search-bar';
import { EstateInfoProps } from 'legacy-components/estate/estate-info/estate-info';
import { EstatesStatsProps } from 'legacy-components/estate/estate-stats/estate-stats';
import { EstatePropertyItem } from 'legacy-components/estate/estate-property/estate-property';
import { EstateAmenitiesItem } from 'legacy-components/estate/estate-amenities/estate-amenities';
import { EstateMakeOfferCardProps } from 'legacy-pages/estates/estate-make-offer-card/estate-make-offer-card';
import { EstateMapProps } from 'legacy-components/estate/estate-map/estate-map';
import { GoogleMapLocation } from 'legacy-components/google-map/google-map';
import { PropertyCategory, EstateCategoryDto, EstateStatusDto } from 'common/enums/enums';
import { getPropertyImage } from 'helpers/image';
import { format, min, parse } from 'date-fns';
import { UnitExtraDto } from 'common/types/services/api/estates/estate-extra-dto.type';
import { EstateDashboardDto } from 'common/types/services/api/estates/estate-dto.type';

export const fromRentEstateStatusDtoToEstateStatus: Record<EstateStatusDto, IEstateCardStatus> = {
  [EstateStatusDto.Available]: IEstateCardStatus.Available,
  [EstateStatusDto.PendingApproval]: IEstateCardStatus.PendingApproval,
  [EstateStatusDto.Incomplete]: IEstateCardStatus.Incomplete,
  [EstateStatusDto.Unavailable]: IEstateCardStatus.Unavailable,
  [EstateStatusDto.Occupied]: IEstateCardStatus.Occupied,
  [EstateStatusDto.Rejected]: IEstateCardStatus.Rejected,
  [EstateStatusDto.PendingPayment]: IEstateCardStatus.PendingPayment,
};

export const fromRentEstateDtoToEstateList = (
  items: EstateDashboardDto[],
  callbacks: (data: EstateDashboardDto) => void,
  isAuth: boolean,
): IEstateCard[] => {
  return items.map((item) => {
    const cardData = {
      isAuth,
      id: item.id,
      title: item.city,
      address: item.location,
      category: fromEstateCategoryDtoToEstateCategory(item.category),
      images: [getPropertyImage(item.thumbnailUrl)],
      beds: item.beds,
      bathrooms: item.bathrooms,
      price: item.price,
      propertyArea: item.propertyArea,
      onClick: () => callbacks(item),
      type: item.type,
      units: item.units,
    };
    return item?.status ? { ...cardData, status: fromRentEstateStatusDtoToEstateStatus[item?.status] } : cardData;
  });
};

function processRating(rating: number | undefined) {
  const defaultRating = 0;

  if (rating === undefined) return defaultRating;

  const roundedRating = Math.abs(rating % 1) > 0 ? Number(rating?.toFixed(2)) : rating;

  return roundedRating > 0 ? roundedRating : defaultRating;
}

export const fromSelectedEstateDetailsDtoToEstateStatsInfo = (
  unit: EstateUnitDto | undefined,
  extraData?: UnitExtraDto | null,
  estate?: EstateDto | undefined,
): EstatesStatsProps => {
  const status = estate?.status !== EstateStatusDto.Available ? estate?.status : unit?.status;
  return {
    availableOn: unit?.availableOn || '',
    offers: extraData?.offers?.toString() || '0',
    views: extraData?.views?.toString() || '0',
    tours: extraData?.tours?.toString() || '0',
    rating: processRating(extraData?.rating) || 0,
    status: status || null,
    isOwner: estate?.isOwner || false,
  };
};

export const estateCategoryToEstateCategoryDto: Record<PropertyCategory, EstateCategoryDto> = {
  // [PropertyCategory.SingleFamilyHouse]: EstateCategoryDto.SingleFamilyHouse,
  // [PropertyCategory.MultiFamilyHouse]: EstateCategoryDto.MultiFamilyHouse,
  [PropertyCategory.Townhouse]: EstateCategoryDto.Townhouse,
  [PropertyCategory.Apartment]: EstateCategoryDto.Apartment,
  [PropertyCategory.Condo]: EstateCategoryDto.Condo,
  [PropertyCategory.House]: EstateCategoryDto.House,
};

export const estateFiltersToDtoEstateFilters = (filters: EstateSearchBarValues): EstateFiltersRequestDto => {
  return {
    city: filters.city,
    zip: filters.zip,
    street: filters.street,
    state: filters.state,
    lat: filters.lat,
    lng: filters.lng,
    location: filters.location.trim(),
    type: filters.type,
    categories: filters.categories,
    beds: Number(filters.beds),
    bathrooms: Number(filters.bathrooms),
    priceMin: Number(filters.minPrice),
    priceMax: Number(filters.maxPrice),
    page: Number(filters.page),
    perPage: Number(filters.perPage),
    sortBy: filters?.sortBy,
    order: filters?.order,
  };
};

const estateStatusDtoToCardStatus: Record<EstateStatusDto, IEstateCardStatus> = {
  [EstateStatusDto.Available]: IEstateCardStatus.Available,
  [EstateStatusDto.Incomplete]: IEstateCardStatus.Incomplete,
  [EstateStatusDto.PendingApproval]: IEstateCardStatus.PendingApproval,
  [EstateStatusDto.Unavailable]: IEstateCardStatus.Unavailable,
  [EstateStatusDto.Occupied]: IEstateCardStatus.Occupied,
  [EstateStatusDto.Rejected]: IEstateCardStatus.Rejected,
  [EstateStatusDto.PendingPayment]: IEstateCardStatus.PendingPayment,
};

export const fromEstateCategoryDtoToEstateCategory = (type: EstateCategoryDto | undefined): string => {
  switch (type) {
    case EstateCategoryDto.MultiFamilyHouse:
      return 'Multi Unit';
    case EstateCategoryDto.SingleFamilyHouse:
      return 'Single Unit';
    case EstateCategoryDto.Apartment:
      return 'Apartment';
    case EstateCategoryDto.Barn:
      return 'Barn';
    case EstateCategoryDto.Cabin:
      return 'Cabin';
    case EstateCategoryDto.Condo:
      return 'Condo';
    case EstateCategoryDto.GuestsHouse:
      return 'Guests House';
    case EstateCategoryDto.Farm:
      return 'Farm';
    case EstateCategoryDto.HouseBoat:
      return 'House Boat';
    case EstateCategoryDto.Mobile:
      return 'Mobile';
    case EstateCategoryDto.TinyHome:
      return 'TinyHome';
    case EstateCategoryDto.House:
      return 'House';
    case EstateCategoryDto.Townhouse:
      return 'Townhouse';
    default:
      return '';
  }
};

export const fromEstateDtoToCard = (item: EstateDashboardDto): IEstateCard => {
  return {
    id: item.id,
    title: item.city,
    address: item.location,
    category: fromEstateCategoryDtoToEstateCategory(item.category),
    images: [item.thumbnailUrl],
    beds: item.beds,
    bathrooms: item.bathrooms,
    price: item.price,
    propertyArea: item.propertyArea,
    status: item.status && estateStatusDtoToCardStatus[item.status], // TODO: Add status
    type: item.type,
    units: item.units,
  };
};

export const fromEstateDetailsDtoToEstateLocationInfo = (data?: EstateDto): EstateInfoProps => {
  return {
    location: data?.city || '',
    address: data?.location || '',
    type: fromEstateCategoryDtoToEstateCategory(data?.category),
  };
};

export const fromEstateDetailsDtoToEstateMakeOfferCard = (
  unit: EstateUnitDto | undefined,
  details: EstateDto | null,
): Omit<EstateMakeOfferCardProps, 'onClick'> => {
  return {
    price: unit?.price || 0,
    beds: unit?.beds || 0,
    bathrooms: unit?.bathrooms || 0,
    propertyArea: unit?.propertyArea || 0,
    deposit: unit?.depositValue || 0,
    commissionPercent: details?.commissionPercent || 0,
    status: (details?.status === EstateStatusDto.Available ? unit?.status : details?.status) || '',
    otherFee: unit?.otherFee || [],
    firstMonth: unit?.firstMonthPrice || 0,
    lastMonth: unit?.lastMonthPrice || 0,
    fixedCommission: details?.fixedCommission || 0,
    leaseLength: unit?.leaseLength || 0,
  };
};

export const fromEstateDetailsDtoToEstateMap = (data?: EstateDto): EstateMapProps => {
  const location: GoogleMapLocation = {
    lng: data?.longitude || 0,
    lat: data?.latitude || 0,
  };

  return {
    title: 'Property Map',
    location,
    onGetDirection: () => {
      window.open(`http://maps.google.com/maps?z=20&t=h&q=${location.lat},${location.lng}`, '_blank');
    },
  };
};

export const fromEstateDetailsDtoToEstateStatsInfo = (
  data?: EstateDto,
  extraData?: UnitExtraDto | null,
  unit?: EstateUnitDto,
): EstatesStatsProps => {
  const getMinAvailbleOnDate = (units?: EstateDto['units']) => {
    if (units) {
      const dateArr = units.map((unit) => parse(unit.availableOn, 'yyyy-MM-dd', new Date()));
      return format(min(dateArr), 'MM-dd-yyyy');
    }

    return '';
  };

  const status = data?.status !== EstateStatusDto.Available ? data?.status : unit?.status;

  return {
    isOwner: Boolean(data?.isOwner),
    availableOn: getMinAvailbleOnDate(data && data.units),
    offers: extraData?.offers?.toString() || '0',
    views: extraData?.views?.toString() || '0',
    tours: extraData?.tours?.toString() || '0',
    rating: processRating(extraData?.rating) || 0,
    status: status || null,
  };
};

export const getEstateProperties = (data: EstateDto | null): EstatePropertyItem[] => {
  // const yesOrNo = (data: any) => data ? 'Yes' : 'No'
  return [
    // {
    //     name: 'Bedroom (sq ft)',
    //     value: String(data?.bathroomArea) || '',
    // },
    // {
    //     name: 'Living Room (sq ft)',
    //     value: String(data?.livingRoomArea) || '',
    // },
    // {
    //     name: 'Dining Room (sq ft)',
    //     value: String(data?.diningRoomArea) || '',
    // },
    // {
    //     name: 'Kitchen (sq ft)',
    //     value: String(data?.kitchenArea) || '',
    // },
    // {
    //     name: 'Balcony',
    //     value: yesOrNo(data?.balcony),
    // },
    // {
    //     name: 'Wi-Fi',
    //     value: yesOrNo(data?.wiFi),
    // },
    // {
    //     name: 'Private entrance',
    //     value: yesOrNo(data?.privateEntrance),
    // },
    // {
    //     name: 'Window bars',
    //     value: yesOrNo(data?.windowBars),
    // },
    // {
    //     name: 'Privacy',
    //     value: 'Screen Doors & Windows'
    // },
    {
      name: 'Safety',
      value: 'CCTV Available',
    },
    {
      name: 'Heating',
      value: 'Forced Air',
    },
    {
      name: 'Cooling',
      value: 'Ceiling Fan, Central',
    },
    {
      name: 'Parking Spaces',
      value: '3',
    },
  ].filter((item) => Boolean(item.value));
};

export const getEstateAmenities = (data: EstateDto | null): EstateAmenitiesItem[] => {
  return data?.amenities
    .flatMap((x) => x.amenities)
    .map((x) => ({
      name: x.name,
      icon: x.image,
    })) as EstateAmenitiesItem[];
};

export const getUnitAmenities = (data?: EstateDto, selectedUnit?: EstateUnitDto): EstateAmenitiesItem[] => {
  if (!data || !selectedUnit) {
    return [];
  }

  const unit = data.units.find((unit) => unit.id === selectedUnit.id);
  if (!unit) {
    return [];
  }

  return unit.amenities
    .flatMap((x) => x.amenities)
    .map((x) => ({
      name: x.name,
      icon: x.image,
    })) as EstateAmenitiesItem[];
};
