import { AuthApiPath, StorageKey } from 'common/enums/enums';
import {
  InvestorSignInRequestDto,
  UserByResetPasswordTokenDto,
  UserDto,
  UserResetPasswordRequestDto,
  UserSendResetPasswordEmailRequestDto,
  UserSignInDto,
  UserSignInRequestDto,
  UserSignUpRequestDto,
} from 'common/types/types';
import { UserDetailsDto } from 'common/types/services/api/user/user-details-dto.type';
import { UserChangePasswordRequestDto } from 'common/types/services/api/user/user-change-password-request-dto.type';
import {
  UpdateVerificationFiles,
  UploadVerificationFileDto,
  VerificationFileDto,
} from 'common/types/services/api/accessAccounts/verification-file.dto';
import { axiosAppOriginInstance, axiosInstance, axiosInstanceWithHeaders } from './axiosInstance';
import qs from 'qs';
import { UpdateCurrentUserDto } from 'common/types/services/api/user/update-current-user-dto.type';
import { UpdateCurrentUserPhoneDto } from 'common/types/services/api/user/update-current-user-phone-dto.type';
import { GetSupportResponseDto } from 'common/types/services/api/user/get-support-response-dto.type';
import { Cookies } from 'react-cookie';
import {
  UserAuth2FAPhoneVerificationDto,
  UserAuthPhoneVerificationDto,
} from 'common/types/services/api/user/user-auth-phone-verification-dto.type';
import { notification, storage } from 'services/services';
import queryClient from 'config/query-client';
import { isEqual } from 'lodash-es';
import { QueryKey } from 'common/enums/query-key';
import { VerificationDetailsFormValues } from 'legacy-pages/renter/profile/renter-profile/renter-profile-information/renter-verification-details-form/types';
import { verificationFormFieldKeyToVerificationFileTypeMap } from 'legacy-pages/renter/profile/renter-profile/renter-profile-information/renter-verification-details-form/mappings';
import { AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from 'axios';
import { UserValidationDto } from 'common/generated/types/user-validation-dto';
import { PersonaVerificationRequest } from 'common/generated/types/persona-verification-request';
import { UserPersonaVerificationDto } from 'common/generated/types/user-persona-verification-dto';

export const signInInvestor = async (payload: InvestorSignInRequestDto) => {
  const response = await axiosInstance.post<void, string>(`${AuthApiPath.SIGN_IN_INVESTOR}`, payload);
  const cookies = new Cookies();
  cookies.set('user-access-key', response);
};

export const signIn = ({ username, password }: UserSignInRequestDto): Promise<UserSignInDto> => {
  return axiosAppOriginInstance.post(
    `${AuthApiPath.SIGN_IN}`,
    qs.stringify({ client_id: 'ro.client', client_secret: 'secret', grant_type: 'password', username, password }),
  );
};

export const logout = () => {
  storage.removeItem(StorageKey.ACCESS_TOKEN);
  storage.removeItem(StorageKey.REFRESH_TOKEN);
  queryClient.invalidateQueries({ queryKey: [QueryKey.User], exact: true });
  queryClient.removeQueries({ predicate: (query) => !isEqual(query.queryKey, [QueryKey.User]) });
  notification.success('You have successfully logged out of the account.');
};

export const renewToken = (refreshToken: string): Promise<UserSignInDto> => {
  return axiosAppOriginInstance.post(
    `${AuthApiPath.SIGN_IN}`,
    qs.stringify({
      client_id: 'ro.client',
      client_secret: 'secret',
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  );
};

export const signUp = (payload: UserSignUpRequestDto): Promise<string> => {
  return axiosInstance.post(`${AuthApiPath.SIGN_UP}`, payload);
};

export const requestPhoneConfirmation = (payload: string) => {
  return axiosInstance.post(`${AuthApiPath.SEND_PHONE_CONFIRMATION_CODE}/${payload}`);
};

export const sendPhoneConfirmation = (payload: UserAuthPhoneVerificationDto) => {
  return axiosInstance.put(
    `${AuthApiPath.SEND_PHONE_CONFIRMATION_CODE}/${payload.registrationId}/confirm?code=${payload.code}`,
  );
};

export const getCurrentUser = (): Promise<UserDto> => {
  return axiosInstance.get(`${AuthApiPath.CURRENT_USER}`);
};

export const updateCurrentUser = (data: UpdateCurrentUserDto): Promise<boolean> => {
  return axiosInstance.put(`${AuthApiPath.CURRENT_USER}`, data);
};

export const updateCurrentUserPhone = (data: UpdateCurrentUserPhoneDto): Promise<boolean> => {
  return axiosInstance.put(`${AuthApiPath.CURRENT_USER}/phone`, data);
};

export const sendResetPasswordEmail = (payload: UserSendResetPasswordEmailRequestDto): Promise<boolean> => {
  return axiosInstance.post(`${AuthApiPath.SEND_RESET_PASSWORD_EMAIL}`, payload);
};

export const getUserByResetPasswordToken = (token: string): Promise<UserByResetPasswordTokenDto> => {
  return axiosInstance.get(`${AuthApiPath.GET_USER_BY_RESET_PASSWORD_TOKEN}?${qs.stringify({ token })}`);
};

export const resetPassword = (data: UserResetPasswordRequestDto): Promise<boolean> => {
  return axiosInstance.post(`${AuthApiPath.RESET_PASSWORD}`, data);
};

export const changePassword = (data: UserChangePasswordRequestDto): Promise<boolean> => {
  return axiosInstance.post(`${AuthApiPath.CHANGE_PASSWORD}`, data);
};

export const confirmEmail = (token: string): Promise<boolean> => {
  return axiosInstance.put(`${AuthApiPath.CONFIRM_EMAIL}?${qs.stringify({ token })}`);
};
export const verifyEmail = (): Promise<boolean> => {
  return axiosInstance.put(`${AuthApiPath.VERIFY_EMAIL}`);
};

export const getUserProfile = (): Promise<UserDetailsDto> => {
  return axiosInstance.get('/userAccess/authenticatedUser/details');
};

export const getUserProfileValidation = (): Promise<UserValidationDto> => {
  return axiosInstance.get('/userAccess/authenticatedUser/validation');
};

export const getUserPersonaVerification = (): Promise<UserPersonaVerificationDto> => {
  return axiosInstance.get(`${AuthApiPath.PERSONA_VERIFICATION}`);
};

export const requestUserProfilePersonaVerification = (): Promise<UserPersonaVerificationDto> => {
  return axiosInstance.post(`${AuthApiPath.PERSONA_VERIFICATION}`);
};

export const updateUserProfilePersonaVerification = (data: PersonaVerificationRequest): Promise<string> => {
  return axiosInstance.put(`${AuthApiPath.PERSONA_VERIFICATION}`, data);
};

export const updateUserProfile = (data: Partial<Omit<UserDetailsDto, 'id'>>): Promise<boolean> => {
  return axiosInstance.put('/userAccess/authenticatedUser/details', data);
};

export const updateUserProfileImage = (
  image: File,
  onUploadProgress?: AxiosRequestConfig<FormData>['onUploadProgress'],
): Promise<boolean> => {
  const formData = new FormData();
  formData.append('file', image);
  return axiosInstance.put('/userAccess/authenticatedUser/image', formData, {
    onUploadProgress,
  });
};

export const removeUserProfileImage = (): Promise<boolean> => {
  return axiosInstance.delete('/userAccess/authenticatedUser/image');
};

export const getSupport = (): Promise<GetSupportResponseDto> => {
  return axiosInstance.get('/Support/email');
};

export const getVerificationFiles = (): Promise<VerificationFileDto[]> => {
  return axiosInstance.get('/userAccess/authenticatedUser/verification');
};

export const uploadVerificationFile = ({
  file,
  type,
  onUploadProgress,
}: UploadVerificationFileDto & {
  onUploadProgress?: AxiosRequestConfig<FormData>['onUploadProgress'];
}): Promise<boolean> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return axiosInstance.put('/userAccess/authenticatedUser/verification', formData, {
    onUploadProgress,
  });
};

export const removeVerificationFile = (docId: string) => {
  return axiosInstance.delete(`/userAccess/authenticatedUser/verification/${docId}`);
};

export const updateVerificationFiles = async ({
  filesToUpload,
  filesToRemove,
  onUploadProgress,
}: UpdateVerificationFiles & {
  onUploadProgress?: (key: string, progress: AxiosProgressEvent) => void;
}): Promise<void> => {
  await Promise.all(
    filesToUpload.map(([name, { file }]) => {
      const type = verificationFormFieldKeyToVerificationFileTypeMap[name as keyof VerificationDetailsFormValues];
      return uploadVerificationFile({
        type,
        file: file as File,
        onUploadProgress: (progressEvent) => onUploadProgress?.(name, progressEvent),
      });
    }),
  );
  await Promise.all(filesToRemove.map(([, file]) => removeVerificationFile(file.id as string)));
};

export const getTwoFactorState = (): Promise<boolean> => {
  return axiosInstance.get(AuthApiPath.TWO_FACTOR_AUTH);
};

export const getTwoFactorStateWithHeaders = (token: string): Promise<AxiosResponse<boolean>> => {
  const axiosInstance = axiosInstanceWithHeaders(token);
  return axiosInstance.get(AuthApiPath.TWO_FACTOR_AUTH);
};

export const sendTwoFactorPhoneConfirmation = (payload: UserAuth2FAPhoneVerificationDto) => {
  const axiosInstance = axiosInstanceWithHeaders(payload?.token);
  return axiosInstance.post(`${AuthApiPath.TWO_FACTOR_AUTH}`, { code: payload?.code });
};

export const chate2FAState = (enable2FA: boolean): Promise<void> => {
  return axiosInstance.put(`${AuthApiPath.TWO_FACTOR_AUTH}`, { isEnabled: enable2FA });
};

export const sendTwoFactorPhoneCode = (token: string): Promise<void> => {
  const axiosInstance = axiosInstanceWithHeaders(token);
  return axiosInstance.post(`${AuthApiPath.TWO_FACTOR_AUTH}/send`);
};

export const refreshToken = (refresh_token: string): Promise<UserSignInDto> => {
  return axiosAppOriginInstance.post(
    `${AuthApiPath.SIGN_IN}`,
    qs.stringify({ client_id: 'ro.client', client_secret: 'secret', grant_type: 'refresh_token', refresh_token }),
  );
};

export const sendUserVerificationCode = (method: string) => {
  return axiosInstance.get(`${AuthApiPath.SEND_USER_VERIFICATION_CODE}?method=${method}`);
};

export const updateUserEmail = (data: any) => {
  return axiosInstance.post(`${AuthApiPath.UPDATE_USER_EMAIL}`, data);
};

export const isEmailExists = (email: string) => {
  return axiosInstance.get(`${AuthApiPath.IS_EMAIL_EXISTS}?email=${email}`);
};
