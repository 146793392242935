import { Table } from 'legacy-components/componets';
import { styleConfig } from './config';
import React, { useMemo, useState } from 'react';
import { OffersItemDto, TablePaginationProps } from 'common/types/types';
import { OffersActionsCell, OffersTableRow } from './common/types/OfferTable.types';
import { useNavigate } from 'react-router-dom';
import { OfferTitle } from './components/OfferTitle/OfferTitle';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { TablePagination } from 'legacy-components/ui/table/table-pagination';
import { OfferMobileVersion } from './components/OfferMobileVersion/OfferMobileVersion';
import { AppRoute, EstateTypeDto, NotificationType, OfferStatusDto } from 'common/enums/enums';
import { OFFERS } from './common/constants/OfferPage.constants';
import { ACTIONS, DATE, OFFER, PROPERTY, STATUS } from './common/constants/OfferPageConfig.constants';
import { OffersTableAccessor } from './common/enums/OfferTableAccessor';
import { OfferDateCell } from './components/OfferDateCell';
import { OffersPropertyCell } from './components/OfferPropertyCell';
import { OfferPriceCell } from './components/OfferPriceCell';
import { OfferStatusCell } from './components/OfferStatusCell/OfferStatusCell';
import { OfferActionCell } from './components/OfferActionCell';
import {
  createEnumDelimitedArrayParam,
  createEnumParam,
  NumberParam,
  QueryParamConfig,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ConfirmCancelOfferModal } from './components/confirm-cancel-offer-modal';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
import { SortOrder } from 'common/types/sort-order';
import { useCancelOffer, useRenterOffers } from 'hooks/query';
import { OffersFilters } from 'components/organisms/Filters/OffersFilters';
import { EditOffersModal } from './common/types/Offers.types';
import { EditOfferModal } from 'components/organisms/EditOfferModal/EditOfferModal';
import { getDayjs } from 'helpers/date';
import { MakeOfferValues } from 'components/organisms/EditOfferModal/EditOfferModal.types';
import useUpdateOffer from 'hooks/query/offers/use-update-offer';
import { uploadOfferFiles } from 'services/api/offers-api.service';
import { OfferFileDto } from 'common/types/services/api/offers/offer-file-dto.type';
import { notification } from 'services/services';
import { ViewRejectionReasonModal } from 'components/organisms/RejectOfferModalByLandlord/ViewRejectionReason/ViewRejectionReason';
import { generateDynamicPath } from 'helpers/routes-path';

type RenterOffersQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  statuses: QueryParamConfig<`${OfferStatusDto}`[]>;
  proposedRentMin: QueryParamConfig<number, number | null | undefined>;
  proposedRentMax: QueryParamConfig<number, number | null | undefined>;
  page: QueryParamConfig<number>;
};

const MyOffers = () => {
  const maxItemsPerPage = 5;
  const queryParamConfig = useMemo<RenterOffersQueryParamConfig>(
    () => ({
      sortBy: withDefault(createEnumParam(['date']), 'date'),
      order: withDefault(SortOrderEnumParam, 'desc'),
      statuses: withDefault(
        createEnumDelimitedArrayParam(Object.values(OfferStatusDto) as `${OfferStatusDto}`[], ','),
        [],
      ),
      proposedRentMin: NumberParam,
      proposedRentMax: NumberParam,
      page: withDefault(NumberParam, 1),
    }),
    [],
  );
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const { isMobile } = useWindowSize();
  const [confirmCancelOfferModalOpen, setConfirmCancelOfferModalOpen] = useState(false);
  const [offerIdToCancel, setOfferIdToCancel] = useState('');
  const [confirmOfferModalOpen, setConfirmOfferModalOpen] = useState(false);
  const [selectedRejectedOffer, setSelectedRejectedOffer] = useState<EditOffersModal | null>(null);
  const [selectedOffer, setSelectedOffer] = useState<OffersItemDto | null>(null);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const {
    data: offers,
    isLoading: isOffersLoading,
    isFetching: isOffersFetching,
  } = useRenterOffers(
    {
      page: query.page,
      perPage: maxItemsPerPage,
      sorters: { appliedAt: query.order },
      filters: {
        status: query.statuses,
        proposedRent: { min: query.proposedRentMin || undefined, max: query.proposedRentMax || undefined },
      },
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );

  const total = offers?.totalCount || 0;

  const { mutate: cancelOffer, isPending: isCancelingOfferPending } = useCancelOffer({
    onError: (e) => {
      notification.error(e?.response?.data.detail || 'The offer has not been canceled');
    },
  });
  const { mutateAsync: updateOffer, isPending: isUpdatingOfferPending } = useUpdateOffer({
    onError: (e) => {
      notification.error(e?.response?.data.detail || 'The offer has not been updated');
    },
  });

  const paginationCallback = React.useCallback((newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  }, []);

  const paginationTableProps = React.useMemo<TablePaginationProps>(() => {
    return {
      currentPage: query.page,
      totalCount: total,
      pageSize: maxItemsPerPage,
      onPageChange: paginationCallback,
    };
  }, [query.page, total, maxItemsPerPage, paginationCallback]);
  const navigate = useNavigate();

  const getActions = (item: OffersItemDto): OffersActionsCell => {
    return {
      onViewEstateProfile: () => navigate(`${AppRoute.ESTATES}/${item?.estateId}?unitId=${item?.unitId}`),
      onViewPreApplicationPayment: () => navigate(`${generateDynamicPath(AppRoute.PRE_OFFER_PAYMENT, { offerId: item.id })}`),
      onViewSignLease: () => navigate(`${AppRoute.OFFER_PAYMENT}/${item.id}`),
      onCancel: async () => {
        setOfferIdToCancel(item.id);
        setConfirmCancelOfferModalOpen(true);
      },
      onResend: () => {
        setSelectedRejectedOffer({
          offerId: item?.id,
          estateId: item?.estateId,
          moveInDate: item?.moveInDate,
          availableOn: item?.unitAvailableOn,
          occupants: item?.occupants?.toString(),
          proposedRent: item?.proposedRent?.toString(),
          description: item?.description,
          unitId: item?.unitId,
        });
        setConfirmOfferModalOpen(true);
      },
      id: item.id,
      status: item.status,
      onViewRejectionReason: () => {
        setSelectedOffer(item);
        setOpenRejectModal(true);
      },
      rejectionReason: item?.rejectReason,
    };
  };

  const submitUpdateOffer = async ({
    bankStatements,
    values,
  }: {
    bankStatements: File[] | OfferFileDto[];
    values: MakeOfferValues;
  }) => {
    setConfirmOfferModalOpen(false);
    await updateOffer({
      unitId: selectedRejectedOffer?.unitId || '',
      moveInDate: getDayjs(values.date).format('YYYY-MM-DD'),
      proposedRent: Number(values.proposedRent),
      occupants: Number(values.occupants),
      description: values.note,
      offerId: selectedRejectedOffer?.offerId || '',
    });

    const newFiles = (bankStatements as (File | OfferFileDto)[]).filter(
      (statement: File | OfferFileDto) => !(statement && 'id' in statement),
    ) as File[];

    if (newFiles.length > 0) await uploadOfferFiles(selectedRejectedOffer?.offerId || '', newFiles);
  };

  const formattedTableData = React.useMemo<OffersTableRow[]>(() => {
    return (
      offers?.items.map((item) => ({
        date: item?.appliedAt,
        property: {
          location: item?.location,
          price: item?.price,
          image: item?.thumbnailUrl,
          isMulti: item?.estateType === EstateTypeDto.MultiUnit,
          unitNumber: item?.unitNumber,
        },
        proposedRent: item?.proposedRent,
        status: item?.status,
        actions: getActions(item),
      })) || []
    );
  }, [offers, getActions]);

  const tableProps = {
    isLoading: isOffersLoading,
    data: formattedTableData,
    paginationProps: {
      currentPage: query.page,
      totalCount: total,
      pageSize: maxItemsPerPage,
      onPageChange: paginationCallback,
    },
  };

  const columns: TableColumn<OffersTableRow>[] = [
    {
      Header: DATE,
      accessor: OffersTableAccessor.Date,
      Cell: OfferDateCell,
      width: '15%',
      minWidth: 150,
      sorter: true,
      sortOrder: query.order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
    },
    {
      Header: PROPERTY,
      accessor: OffersTableAccessor.Property,
      Cell: OffersPropertyCell,
      width: '35%',
      minWidth: 300,
    },
    {
      Header: OFFER,
      accessor: OffersTableAccessor.ProposedRent,
      Cell: OfferPriceCell,
      width: '20%',
      minWidth: 150,
    },
    {
      Header: STATUS,
      accessor: OffersTableAccessor.Status,
      Cell: OfferStatusCell,
      width: '20%',
      minWidth: 100,
    },
    {
      Header: ACTIONS,
      accessor: OffersTableAccessor.Actions,
      Cell: OfferActionCell,
      width: '10%',
      minWidth: 100,
    },
  ];

  return (
    <div className='grow overflow-x-auto p-4 md:px-[34px] md:py-[28px] shadow-white-xl'>
      <ConfirmCancelOfferModal
        open={confirmCancelOfferModalOpen}
        onOk={() => {
          cancelOffer(offerIdToCancel);
          setConfirmCancelOfferModalOpen(false);
        }}
        onCancel={() => setConfirmCancelOfferModalOpen(false)}
      />
      <EditOfferModal
        unitId={selectedRejectedOffer?.unitId || ''}
        onClose={() => setConfirmOfferModalOpen(false)}
        open={confirmOfferModalOpen}
        estateId={selectedRejectedOffer?.estateId || ''}
        initialValues={{
          date: selectedRejectedOffer?.moveInDate || new Date(),
          proposedRent: selectedRejectedOffer?.proposedRent || '',
          occupants: selectedRejectedOffer?.occupants || '',
          note: selectedRejectedOffer?.description || '',
        }}
        availableOn={selectedRejectedOffer?.availableOn || ''}
        onSubmit={submitUpdateOffer}
        offerId={selectedRejectedOffer?.offerId || ''}
      />
      <ViewRejectionReasonModal
        offer={selectedOffer}
        isOpen={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
      />
      <div className='flex justify-between items-center mb-2 md:mb-9'>
        <OfferTitle title={OFFERS} />
        <OffersFilters />
      </div>
      <div className='flex flex-col gap-9'>
        {isMobile ? (
          <div>
            <div className='flex flex-col gap-4 mb-4'>
              {tableProps.data.map((el, index) => OfferMobileVersion(el, index))}
            </div>
            <TablePagination {...tableProps.paginationProps} />
          </div>
        ) : (
          <Table
            styleConfig={styleConfig}
            data={formattedTableData || []}
            columns={columns}
            isLoading={isOffersLoading || isOffersFetching || isCancelingOfferPending || isUpdatingOfferPending}
            paginationProps={paginationTableProps}
          />
        )}
      </div>
    </div>
  );
};

export { MyOffers };
