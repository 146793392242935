import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { updateOffer } from 'services/api/offers-api.service';
import { CreateOfferRequestDto } from 'common/types/services/api/offers/offer-create-request-dto.type';
import { notification } from 'services/services';
import { QueryKey } from 'common/enums/query-key';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';

export default function useUpdateOffer(
  options?: Omit<
    UseMutationOptions<string, AxiosError<BackendError>, CreateOfferRequestDto & { offerId: string }, unknown>,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ ...query }) => updateOffer(query?.offerId, query),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Offers] });
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onError: (e) => {
      notification.error(e?.response?.data?.detail || 'The offer has not been updated');
    },
    ...options,
  });
}
