import { EstateShortPropertyInfo } from 'legacy-components/componets';
import { EstateShortPropertyInfoProps } from '../estate-short-property-info/estate-short-property-info';
import { OtherFeeType } from 'common/mappers/property';
import { EstatePriceDetails } from 'components/atoms/EstatePriceDetails/EstatePriceDetails';

export type AdditionalPaymentInfoProps = {
  otherFee: OtherFeeType[];
  firstMonth: number;
  lastMonth: number;
  price: number;
  leaseLength: number;
  deposit: number;
  axxelistFees: number;
};

export type EstatesRentInfoCardProps = {
  shortPropertyInfo: EstateShortPropertyInfoProps;
  additionalPaymentInfo: AdditionalPaymentInfoProps;
  estateId: string;
};

const EstatesRentInfoCard = ({ additionalPaymentInfo, shortPropertyInfo, estateId }: EstatesRentInfoCardProps) => {
  return (
    <div className='grid gap-6 p-6 border border-secondary shadow-card rounded-2xl min-w-[250px]'>
      <EstateShortPropertyInfo {...shortPropertyInfo} />
      <EstatePriceDetails {...additionalPaymentInfo} />
    </div>
  );
};

export { EstatesRentInfoCard };
