import React from 'react';
import { LocationMarker } from 'common/types/types';

const NotAuthorizeMapMarker = (props: LocationMarker) => {
  const commonStyles = 'rounded-full bg-[#9747FF] bg-opacity-20';
  return (
    <div className={`relative w-[200px] h-[200px] ${commonStyles}`} style={{ transform: 'translate(-50%, -50%)' }}>
      <div
        className={`absolute w-[140px] h-[140px] ${commonStyles} top-0 left-0 right-0 bottom-0 mx-auto my-auto`}
      ></div>
    </div>
  );
};

export { NotAuthorizeMapMarker };
