import React, { ReactNode } from 'react';
import { useAuth } from 'hooks';
import { GoogleMap, NotAuthorizeMapMarker } from 'legacy-components/componets';
import { GoogleMapLocation } from 'legacy-components/google-map/google-map';
import { useNavigate } from 'react-router-dom';

export type EstateMapProps = {
  title: string;
  width?: number;
  height?: number;
  location: GoogleMapLocation;
  onGetDirection: () => void;
};

const EstateMap = ({ title, location, width, height, onGetDirection }: EstateMapProps) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const markers = ((): ReactNode => {
    return isAuthenticated ? undefined : <NotAuthorizeMapMarker lat={location.lat} lng={location.lng} />;
  })();

  const handleGetDirection = (e: React.MouseEvent<HTMLDivElement>) => {    
    if (!isAuthenticated) {
      navigate('/auth/sign-in');
    } else {
      e.stopPropagation();
      onGetDirection();
    }
  };

  return (
    <div className='flex flex-col w-full h-full gap-[37px]'>
      <h5 className='font-bold text-black text-[22px]'>{title}</h5>
      <GoogleMap markers={markers} location={location} height={height} width={width} />
      <div className='flex items-center cursor-pointer'>
        <div className='text-base font-semibold text-primary mr-[13px]' onClick={handleGetDirection}>
          Get directions
        </div>
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'>
          <path
            d='M10.9984 3.40024L2.12344 12.3002C1.92344 12.5002 1.68577 12.6002 1.41044 12.6002C1.13577 12.6002 0.898437 12.5002 0.698437 12.3002C0.498437 12.1002 0.398438 11.8626 0.398438 11.5872C0.398438 11.3126 0.498437 11.0752 0.698437 10.8752L9.59844 2.00024H1.99844C1.7151 2.00024 1.47744 1.90458 1.28544 1.71324C1.0941 1.52124 0.998437 1.28358 0.998437 1.00024C0.998437 0.716911 1.0941 0.479244 1.28544 0.287244C1.47744 0.095911 1.7151 0.000244141 1.99844 0.000244141H11.9984C12.2818 0.000244141 12.5191 0.095911 12.7104 0.287244C12.9024 0.479244 12.9984 0.716911 12.9984 1.00024V11.0002C12.9984 11.2836 12.9024 11.5209 12.7104 11.7122C12.5191 11.9042 12.2818 12.0002 11.9984 12.0002C11.7151 12.0002 11.4778 11.9042 11.2864 11.7122C11.0944 11.5209 10.9984 11.2836 10.9984 11.0002V3.40024Z'
            fill='#66CCFF'
          />
        </svg>
      </div>
    </div>
  );
};

export { EstateMap };
